<template src="./UserRestore.html"></template>

<script>

import hex_fucn from '@/utils/sha512'

export default {
  name: "UserRestore",
  data: () => ({

    // первый шаг - спросить почту
    // второй, когда перешли по ссылке из почты - спросить новый пароль
    isFirstStep: true,

    theater:  0, // необязательно, если не нашли
    link:     "",

    isGoodLink:false,

    show_pwd: false,
    password: "",

    rules: [
      value => !!value || 'Обязательное поле.',
      v => v.length >= 8 || 'Как минимум 8 символов',
    ],

    email: '',
    emailRules: [
      v => !!v || 'Обязательное поле',
      v => /.+@.+/.test(v) || 'Почта не похожа на почту',
    ],

    valid: false,
    loading: false,
    overlay:false,
    message: 'Ничего не сделал',






  }),

  created() {

    console.log('restore user');

    if (this.$route.params.link) {

      this.link = this.$route.params.link;
      console.log(this.link);

      this.checkRestoreLink();

      // этап, когда спрашиваем новый пароль
      this.isFirstStep = false;
    }



    if (this.$store.getters.isAuthenticated){

      // fetch(this.$store.state.auth.path+"/event/"+this.id+"", {
      //   method: 'GET',
      //   headers: {
      //     'Authorization': 'Bearer '+ this.$store.state.auth.token,
      //     'Content-Type': 'application/json'
      //   }
      // })
      //     .then(response => response.json())
      //     .then((data) => {
      //       this.event = data.data;
      //       this.event_ready = true;
      //     });

    }

  },

  mounted() {

    console.log('mount');
  },



  methods:{

    validate () {
      this.$refs.form.validate()
      if (this.valid){
        console.log('А теперь все правильно и можно отправлять');
        this.onClickSendLink();
      }
    },

    validate_pwd () {
      this.$refs.form_pwd.validate()
      if (this.valid){
        console.log('А теперь все правильно и можно отправлять222');
        this.onClickChangePwd();
      }
    },

    // проверить - рабочая ли ссылка по которой пришли
    checkRestoreLink(){

      console.log('check');

      let post_data = {

        base:{
          type:"restore",
          act: "visit"
        },

        user:{
          link: this.link,
        }

      };

      console.log(post_data);

      let pr2 = this.$store.state.api.admUser(post_data);

      pr2.then( data =>{

        console.log(data);

        if (data.b){
          this.isGoodLink = true;
        }
        else{
          this.message = "Ссылка для восстановления просрочена";

          this.message =
              "Ссылка для восстановления просрочена<br>" +
              "Попробуем еще раз?<br><br>" +

              "<a href='/user/r'>Повторить</a>"
          ;

          this.overlay = true;
        }

      })

    },

    onClickChangePwd(){

      this.loading = true;
      this.message = "Проверяем данные";
      this.overlay = true;

      console.log('change');

      let post_data = {

        base:{
          type:"restore",
          act: "change_pwd"
        },

        user:{
          link:       this.link,
          pwd_hash:   hex_fucn.hex_sha512(this.password)
        }

      };

      console.log(post_data);

      let pr2 = this.$store.state.api.admUser(post_data);

      pr2.then( data =>{

        console.log(data);

        if (data.b){
          this.message = "Поменяли пароль. Можно входить.";
        }
        else{
          this.message =
              "Что-то не вышло с первого раза. Печально.";
        }

        this.loading = false;

      })




    },

    onClickSendLink(){

      this.loading = true;
      this.message = "Ищем вашу почту";
      this.overlay = true;

      console.log('send');

      let post_data = {

        base:{
          type:"restore",
          act: "create"
        },

        user:{
          email:    this.email.trim(),
          theater:  this.theater
        }

      };

      console.log(post_data);

      let pr2 = this.$store.state.api.admUser(post_data);

      pr2.then( data =>{

        console.log(data);

        if (data.b){
          this.message = "Отправили ссылку на почту: <br><b>" + this.email + "</b>";
        }
        else{
          this.message =
              "Мы не нашли указанную почту: <br>" +
              "<b>" + this.email + "</b><br><br>" +
              "Попробуем другую?";
        }

        this.loading = false;

      })


    }


  }
}



</script>

<style scoped>

</style>
